
  import { defineComponent, ref, onBeforeMount, watch, onMounted } from 'vue';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    CampTable,
    CampTableTh,
    CampTableTd,
    CampEmptyListFeedbackV2
  } from '@/components';
  import { campHandleEmptyFields } from '@/composables/DataValidation';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useStoreStore } from '@/store/StoreStore';
  import { useAuthStore } from '@/store/AuthStore';
  import { useFilterMainStore } from '@/store/FilterMainStore'
  import ptBr from "element-plus/lib/locale/lang/pt-br";
  import moment from 'moment';
  import { TOptions, uploadMediaFromClient } from '@/services/AzureBlobService';
  import ModalQuiz, { TQuestion, createAlternative } from '@/views/Training/Components/ModalQuiz.vue';
  import MediaSystemEngine from '@/views/AwardsCatalog/Components/MediaSystemEngine.vue';
  import { IMediaFile, initMediaFile } from './PutPage.vue';
  
  interface IAwardData {
    id?: number,
    title: string,
    description: string,
    url: string | null,
    active: boolean | number,
    points: number,
    expiration_date: string
    id_company: number | null,
  }
  
  interface IAwardResponse {
    data: {
      error: boolean,
      message: string,
      data: any
      errorCode: string
    },
    status: number
  }
  
  export default defineComponent({
    name: "AwardsCatalogPost",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn,
      CampTable,
      CampTableTh,
      CampTableTd,
      CampEmptyListFeedbackV2,
      MediaSystemEngine
    },
    setup() {
      const filterMainStore = useFilterMainStore()
      const isLoading = ref(true)
      const { showTimeAlert } = useAlert()
      const loaderStore = useLoaderStore()
      const isRequiredField = ref(false)
      const infoMaxCharacters = ref<string | null>(null)
      const infoMaxTitle = ref<string | null>(null)
      const infoMaxCharactersWarning = ref<string | null>(null)
      const infoMaxCharactersTitle = ref<string | null>(null)
      const disabledBtnForm = ref(true)
      const companyStore = useCompanyStore()
      const activeStatus = ref(false)
      const currentDate = new Date()
      const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
      const mediaFile = ref<IMediaFile>({ ...initMediaFile })
      const INIT_DATA: IAwardData = {
        title: "",
        points: 10,
        description: "",
        url: "",
        expiration_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        active: activeStatus.value ? 1 : 0,
        id_company: companyStore.getId
      }
      const formModel = ref<IAwardData>(INIT_DATA)
      /** Attached File List */
      const attachedFileList = ref<File[] | null>(null)
  
      // Functions
      const resetWarning = () => isRequiredField.value = false
  
      /** Handle Attached File */
      function handleAttachedFileChange(event: Event) {
        const fileInput = event.target as HTMLInputElement;
        const file: File | null | undefined = fileInput.files?.[0];
  
        if(!file){
          return
        }
  
        if(!(Array.isArray(attachedFileList.value))){
          attachedFileList.value = new Array()
        }
  
        if(attachedFileList.value.find(el => el.name === file.name)){
          return showTimeAlert('Arquivo já inserido!', 'error')
        }
        
        attachedFileList.value.push(file)
        fileInput.value = '';
      }
  
      /** Date */
      const handleDisabledDateMin = (time: Date) => time.getTime() < maxDate.getTime()
  
      /** Size Desc */
      function handleSizeDesc(max = 255) {
        const length = formModel.value.description.length
        infoMaxCharacters.value = `${length}/${max}`
        if(length > max){
          infoMaxCharactersWarning.value = `Máximo de ${max} caracteres atingido!`
        }
        else{
          infoMaxCharactersWarning.value = null
        }
      }

      function handleSizeTitle(max = 50) {
        const title = formModel.value.title.length
        infoMaxTitle.value = `${title}/${max}`
        if(title > max){
          infoMaxCharactersTitle.value = `Máximo de ${max} caracteres atingido!`
        }
        else{
          infoMaxCharactersTitle.value = null
        }
      }
  
      /** Handle Open Media Modal */
   
      async function onSubmitForm() {
        isRequiredField.value = false
  
        if(infoMaxCharactersWarning.value || infoMaxCharactersTitle.value){
          showTimeAlert('Verifique os campos obrigatórios', 'error')
          return true
        }
  
        formModel.value.expiration_date = moment(formModel.value.expiration_date).format('YYYY-MM-DD HH:mm:ss')
        formModel.value.active = activeStatus.value ? 1 : 0
        const { id_company, url,active, ...requiredFields } = formModel.value
  
        if(campHandleEmptyFields(requiredFields)) {
          showTimeAlert('Verifique os campos obrigatórios', 'error')
          isRequiredField.value = true
          return true
        }
  
        const bodyToPost = {
          url,
          id_company,
          ...requiredFields,
        }

        if(mediaFile.value.file) {
        const options: TOptions = {
          blobHTTPHeaders: {
            blobContentType: mediaFile.value.contentType
          }
        };
        try {
          loaderStore.open()
          const { request } = (await uploadMediaFromClient(mediaFile.value.file, mediaFile.value.blobName, options))._response
          bodyToPost.url = request.url
        } catch (error) {
          loaderStore.close()
          return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
        }
      }

      /** Exclui URL no Banco do Blob removido na Nuvem */
      if(!mediaFile.value.URL){
        bodyToPost.url = null
      }

      if(mediaFile.value.youtubeURL)
        bodyToPost.url = mediaFile.value.youtubeURL
  
        try {
          loaderStore.open()
          const response: IAwardResponse = await axios.post(`/api/createReward`, { ...bodyToPost })
          showTimeAlert("Prêmio criado com sucesso!")
          formModel.value = { ...INIT_DATA }
          router.push("/catalogo-de-premios")
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response)
              showTimeAlert(error.response?.data.message, "error")
            else
              showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
          loaderStore.close()
        }
      }
  
      /** Handle Filter Modal */
      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }
  
      const handleUpdatedMediaFile = (emittedObject: IMediaFile) => mediaFile.value = emittedObject
  
      // Life cycles
  
      onMounted(() => handleInitFilterModal())
    
      watch(() => formModel.value.description, () => handleSizeDesc())
      watch(() => formModel.value.title, () => handleSizeTitle())
  
      return {
        isRequiredField,
        isLoading,
        companyStore,
        resetWarning,
        formModel,
        infoMaxCharacters,
        infoMaxTitle,
        infoMaxCharactersWarning,
        infoMaxCharactersTitle,
        activeStatus,
        ptBr,
        handleDisabledDateMin,
        onSubmitForm,
        disabledBtnForm,
        handleAttachedFileChange,
        mediaFile,
        handleUpdatedMediaFile,
        attachedFileList,
        moment,
      }
    }
  })
  